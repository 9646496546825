import { z } from "zod";

const InternalPermissions = z.array(z.string());

export const Organization = z.object({
  id: z.string(),
  name: z.string(),
});

export const ProductPermission = z.object({
  role: z.string(),
  product: z.string(),
  subscriptionStatus: z.string().nullable(),
});

export const Permissions = z.object({
  internal: InternalPermissions,
  organizations: z.array(Organization.extend({ products: z.array(ProductPermission) })),
});

export const User = z.object({
  id: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  emailAddress: z.string(),
  avatar: z.string().nullable().optional(),
});

export const OrgProduct = z.object({
  id: z.coerce.number(),
  name: z.enum(["Account Management", "Broadcast", "Songs", "Training"]),
  roles: z.array(z.string()),
  seatCount: z.coerce.number().nonnegative(),
  seatLimit: z.coerce.number().nonnegative(),
  iconUrl: z.string().url().nullable(),
});

export const OrgUser = User.extend({
  status: z.enum(["Member", "Pending", "Deactivated"]),
  products: z.array(OrgProduct.shape.name),
});

export const OrgUserPermissions = z.object({
  id: z.string(),
  name: z.string(),
  products: z.array(ProductPermission),
});

export type User = z.infer<typeof User>;
export type OrgUser = z.infer<typeof OrgUser>;
export type OrgProduct = z.infer<typeof OrgProduct>;
export type Permissions = z.infer<typeof Permissions>;
export type Organization = z.infer<typeof Organization>;
export type ProductPermission = z.infer<typeof ProductPermission>;
