import { toast } from "sonner";
import { z } from "zod";
import { fromZodError } from "zod-validation-error";

import { editProfileSchema } from "~/components/edit-profile-form";
import { routes } from "~/lib/routes";
import { Organization, OrgProduct, OrgUser, OrgUserPermissions, Permissions, User } from "~/lib/schema";

class TWIClient {
  private async get(input: string | URL | Request, init?: Omit<RequestInit, "credentials">) {
    return fetch(input, { ...init, credentials: "include" });
  }

  private parseJSON<T extends z.ZodTypeAny>(data: unknown, schema: T) {
    const result = schema.safeParse(data);
    if (!result.success && process.env.NODE_ENV === "development") {
      console.error(fromZodError(result.error).toString(), data);
      toast.warning("Schema validation failed.", { description: "Check console for details.", duration: 5000 });
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result.data as z.infer<T>;
  }

  async getCurrentUser() {
    const res = await this.get(routes.me());
    if (res.status === 401) {
      throw new Error("Not authenticated");
    }
    const data = this.parseJSON(await res.json(), User);
    return data;
  }

  async getUser(userId: string) {
    const res = await this.get(routes.user({ params: { userId } }));
    if (!res.ok) {
      throw new Error("Error fetching user");
    }
    const data = this.parseJSON(await res.json(), User);
    return data;
  }

  async updateMe(data: z.infer<typeof editProfileSchema>) {
    const res = await this.get(routes.me(), {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!res.ok) {
      throw new Error("Error updating profile");
    }
  }

  async getUserPermissions(organizationId: string, userId: string) {
    const res = await this.get(routes.orgUserPermissions({ params: { organizationId, userId } }));
    if (!res.ok) {
      throw new Error("Error fetching organization user");
    }
    const data = this.parseJSON(await res.json(), z.array(OrgUserPermissions));
    return data;
  }

  async getUsers(organizationId: string) {
    const res = await this.get(routes.orgUsers({ params: { organizationId } }));
    if (!res.ok) {
      throw new Error("Error fetching organization users");
    }

    const data = this.parseJSON(await res.json(), z.array(OrgUser));
    return data;
  }

  async editUser(organizationId: string, userId: string, products: Array<{ id: number; role: string }>) {
    const res = await this.get(routes.orgUserPermissions({ params: { organizationId, userId } }), {
      method: "POST",
      body: JSON.stringify({ products }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!res.ok) {
      throw new Error("Error editing organization user");
    }
  }

  async deactivateUser(organizationId: string, userId: string) {
    const res = await this.get(routes.orgUser({ params: { organizationId, userId } }), {
      method: "DELETE",
    });
    if (res.status !== 204) {
      throw new Error("Error deactivating user");
    }
  }

  async inviteUser(organizationId: string, emailAddress: string, products: Array<{ id: number; role: string }>) {
    const res = await this.get(routes.orgUsers({ params: { organizationId } }), {
      method: "POST",
      body: JSON.stringify({ emailAddress, products }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.status !== 204) {
      throw new Error("Error inviting user");
    }
  }

  async healthcheck() {
    const res = await this.get(routes.api_healthcheck());
    if (res.status !== 200) {
      throw new Error("Healthcheck failed");
    }
    return res.text();
  }

  async getPermissions() {
    const res = await this.get(routes.permissions());
    if (res.status === 404) {
      return {
        internal: [],
        organizations: [],
      };
    }
    const data = this.parseJSON(await res.json(), Permissions);
    return data;
  }

  async getOrganization(organizationId: string) {
    const res = await this.get(routes.org({ params: { organizationId } }));
    if (!res.ok) {
      throw new Error("Error fetching organization");
    }
    const data = this.parseJSON(await res.json(), Organization);
    return data;
  }

  async getProducts(organizationId: string) {
    const res = await this.get(routes.orgProducts({ params: { organizationId } }));
    if (!res.ok) {
      throw new Error("Error fetching organization products");
    }

    const data = this.parseJSON(await res.json(), z.array(OrgProduct));
    return data;
  }
}

export const client = new TWIClient();
