const localPaths = {
  home: "/organizations/$organizationId",
  switch_organization: "/organizations",
  members: "/organizations/$organizationId/members",
  healthcheck: "/healthcheck",
} as const;

const APIPaths = {
  me: "/account/me",
  login: "/account/auth/login",
  logout: "/account/auth/logout",
  secured: "/account/auth/secured",
  permissions: "/account/permissions",
  api_healthcheck: "/account/healthz",
  user: "/account/users/$userId",
  org: "/account/organizations/$organizationId",
  orgUsers: "/account/organizations/$organizationId/users",
  orgProducts: "/account/organizations/$organizationId/products",
  orgUser: "/account/organizations/$organizationId/users/$userId",
  orgUserPermissions: "/account/organizations/$organizationId/users/$userId/permissions",
} as const;

type AllPaths = keyof typeof localPaths | keyof typeof APIPaths;

// Gets us typed path params when calling routes.dynamicPage()
type ExtractParams<Path extends string> = Path extends `${string}/$${infer Param}/${infer Rest}`
  ? ExtractParams<`/${Rest}`> | Param
  : Path extends `${string}/$${infer Param}`
    ? Param
    : never;

type DynamicParams<P extends AllPaths> = ExtractParams<(typeof localPaths & typeof APIPaths)[P]>;

type IsDynamicPath<P extends AllPaths> = DynamicParams<P> extends never ? false : true;

interface Options<P extends AllPaths = AllPaths> {
  params?: IsDynamicPath<P> extends true ? Record<DynamicParams<P>, string> : never;
  search?: Record<string, string>;
  hash?: string;
}

type RouteOptions<P extends AllPaths> = IsDynamicPath<P> extends true ? Options<P> : Omit<Options<P>, "params">;

function routeBuilder<P extends AllPaths>(pathname: P, options?: RouteOptions<P>) {
  const allPaths = { ...localPaths, ...APIPaths };
  let path: string = allPaths[pathname];

  // Replace dynamic parameters in the path
  if (options && "params" in options && options.params) {
    Object.entries(options.params).forEach(([key, value]) => {
      path = path.replace(`$${key}`, value as string);
    });
  }

  const isAPI = pathname in APIPaths;

  // Local paths are relative to the root
  if (!isAPI) {
    if (options?.search) {
      const searchParams = new URLSearchParams();
      Object.entries(options.search).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
      path = `${path}?${searchParams.toString()}`;
    }
    return path;
  }

  // API paths are absolute and need to be prefixed with the API base URL
  const url = new URL(path, import.meta.env.VITE_API_BASE_URL);

  if (options?.search) {
    Object.entries(options.search).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });
  }

  if (options?.hash) {
    url.hash = options.hash;
  }

  if (!URL.canParse(url)) {
    if (process.env.NODE_ENV === "development") {
      throw new Error(`Invalid URL: ${url.toString()}`);
    }
    console.error(`Invalid URL: ${url.toString()}`);
  }

  return url.toString();
}

export const routes = Object.fromEntries(
  Object.entries({ ...localPaths, ...APIPaths }).map(([key]) => [
    key,
    (opts?: RouteOptions<AllPaths>) => routeBuilder(key as AllPaths, opts),
  ]),
) as { [P in AllPaths]: (opts?: RouteOptions<P>) => string };
